<template>
  <div
    class="p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600 before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400 after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700"
  >
    <div class="container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Login Info -->
        <div class="hidden min-h-screen flex-col xl:flex">
          <a class="-intro-x flex items-center pt-5" href="">
            <img class="w-6" src="/static/images/logo-small.png" alt="OMACK Network" />
            <span class="ml-3 text-lg text-white"> OMACK MLM </span>
            <span class="ml-3">
              <app-version />
            </span>
          </a>
          <div class="my-auto">
            <img
              class="-intro-x -mt-16 w-1/2"
              src="/static/images/logo-2.png"
              alt="OMACK Network"
            />
            <div class="-intro-x mt-10 text-4xl font-medium leading-tight text-white">
              A few more clicks to <br />
              access your account.
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
              Manage all your accounts in one place.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppVersion from '@/components/AppVersion.vue';
import '@/app.scss';

export default defineComponent({
  components: {
    AppVersion
  },
  name: 'App',
  setup(_, { attrs }) {
    return {
      props: attrs
    };
  }
});
</script>
