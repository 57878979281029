<template>
  <div
    class="mobile-menu group top-0 inset-x-0 fixed bg-theme-1/90 z-[60] border-b border-white/[0.08] dark:bg-darkmode-800/90 md:hidden before:content-[''] before:w-full before:h-screen before:z-10 before:fixed before:inset-x-0 before:bg-black/90 before:transition-opacity before:duration-200 before:ease-in-out before:invisible before:opacity-0 [&.mobile-menu--active]:before:visible [&.mobile-menu--active]:before:opacity-100"
  >
    <div class="flex h-[70px] items-center px-3 sm:px-8">
      <a class="mr-auto flex" href="">
        <img class="w-6" src="/static/images/logo.svg" alt="OMACK Network" />
      </a>
      <a class="mobile-menu-toggler" href="#">
        <i
          data-tw-merge=""
          data-lucide="bar-chart2"
          class="stroke-1.5 h-8 w-8 -rotate-90 transform text-white"
        ></i>
      </a>
    </div>
    <div
      class="scrollable h-screen z-20 top-0 left-0 w-[270px] -ml-[100%] bg-primary transition-all duration-300 ease-in-out dark:bg-darkmode-800 [&[data-simplebar]]:fixed [&_.simplebar-scrollbar]:before:bg-black/50 group-[.mobile-menu--active]:ml-0"
    >
      <a
        href="#"
        class="fixed top-0 right-0 mt-4 mr-4 transition-opacity duration-200 ease-in-out invisible opacity-0 group-[.mobile-menu--active]:visible group-[.mobile-menu--active]:opacity-100"
      >
        <i
          data-tw-merge=""
          data-lucide="x-circle"
          class="stroke-1.5 mobile-menu-toggler h-8 w-8 -rotate-90 transform text-white"
        ></i>
      </a>
      <ul class="py-2">
        <!-- BEGIN: First Child -->
        <li>
          <a class="menu menu--active" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="home" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Dashboard
              <div :class="`menu__sub-icon transform rotate-180`">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="menu__sub-open">
            <li>
              <a class="menu menu--active" href="enigma-side-menu-dashboard-overview-1-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 1</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-dashboard-overview-2-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 2</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-dashboard-overview-3-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 3</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-dashboard-overview-4-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 4</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="shopping-bag" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              E-Commerce
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-categories-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Categories</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-add-product-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Add Product</div>
              </a>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Products
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-product-list-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Product List</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-product-grid-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Product Grid</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Transactions
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-transaction-list-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Transaction List</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-transaction-detail-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Transaction Detail</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Sellers
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-seller-list-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Seller List</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-seller-detail-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Seller Detail</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-reviews-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Reviews</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-inbox-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="inbox" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">Inbox</div>
          </a>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-file-manager-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="hard-drive" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">File Manager</div>
          </a>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-point-of-sale-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="credit-card" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">Point of Sale</div>
          </a>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-chat-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="message-square" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">Chat</div>
          </a>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-post-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="file-text" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">Post</div>
          </a>
        </li>
        <li>
          <a class="menu" href="enigma-side-menu-calendar-page.html">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="calendar" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">Calendar</div>
          </a>
        </li>
        <li class="menu__divider my-6"></li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="edit" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Crud
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-crud-data-list-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Data List</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-crud-form-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Form</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="users" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Users
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-users-layout-1-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Layout 1</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-users-layout-2-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Layout 2</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-users-layout-3-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Layout 3</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="trello" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Profile
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-profile-overview-1-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 1</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-profile-overview-2-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 2</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-profile-overview-3-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Overview 3</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="layout" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Pages
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Wizards
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-wizard-layout-1-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wizard-layout-2-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wizard-layout-3-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Blog
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-blog-layout-1-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-blog-layout-2-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-blog-layout-3-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Pricing
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-pricing-layout-1-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-pricing-layout-2-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 2</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Invoice
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-invoice-layout-1-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-invoice-layout-2-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 2</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  FAQ
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-faq-layout-1-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 1</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-faq-layout-2-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 2</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-faq-layout-3-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Layout 3</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-login-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Login</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-register-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Register</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-error-page-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Error Page</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-update-profile-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Update profile</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-change-password-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Change Password</div>
              </a>
            </li>
          </ul>
        </li>
        <li class="menu__divider my-6"></li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="inbox" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Components
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Grid
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-regular-table-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Regular Table</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-tabulator-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Tabulator</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Overlay
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-modal-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Modal</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-slide-over-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Slide Over</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-notification-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Notification</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-tab-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Tab</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-accordion-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Accordion</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-button-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Button</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-alert-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Alert</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-progress-bar-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Progress Bar</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-tooltip-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Tooltip</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-dropdown-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Dropdown</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-typography-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Typography</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-icon-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Icon</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-loading-icon-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Loading Icon</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="sidebar" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Forms
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-regular-form-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Regular Form</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-datepicker-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Datepicker</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-tom-select-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Tom Select</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-file-upload-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">File Upload</div>
              </a>
            </li>
            <li>
              <a class="menu" href="javascript:;">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">
                  Wysiwyg Editor
                  <div class="menu__sub-icon">
                    <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
                  </div>
                </div>
              </a>
              <ul class="">
                <li>
                  <a class="menu" href="enigma-side-menu-wysiwyg-editor-classic-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Classic</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wysiwyg-editor-inline-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Inline</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wysiwyg-editor-balloon-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Balloon</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wysiwyg-editor-balloon-block-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Balloon Block</div>
                  </a>
                </li>
                <li>
                  <a class="menu" href="enigma-side-menu-wysiwyg-editor-document-page.html">
                    <div class="menu__icon">
                      <i data-tw-merge="" data-lucide="zap" class="stroke-1.5 w-5 h-5"></i>
                    </div>
                    <div class="menu__title">Document</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-validation-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Validation</div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a class="menu" href="javascript:;">
            <div class="menu__icon">
              <i data-tw-merge="" data-lucide="hard-drive" class="stroke-1.5 w-5 h-5"></i>
            </div>
            <div class="menu__title">
              Widgets
              <div class="menu__sub-icon">
                <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
              </div>
            </div>
          </a>
          <ul class="">
            <li>
              <a class="menu" href="enigma-side-menu-chart-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Chart</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-slider-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Slider</div>
              </a>
            </li>
            <li>
              <a class="menu" href="enigma-side-menu-image-zoom-page.html">
                <div class="menu__icon">
                  <i data-tw-merge="" data-lucide="activity" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="menu__title">Image Zoom</div>
              </a>
            </li>
          </ul>
        </li>
        <!-- END: First Child -->
      </ul>
    </div>
  </div>
</template>
