<template>
  <i
    data-tw-merge=""
    :data-lucide="name"
    :class="`stroke-${stroke} h-${sizeClass} w-${sizeClass} ${color} ${classAttributes.class ?? ''}`"
  ></i>
</template>

<script lang="ts" setup>
import { defineProps, useAttrs } from 'vue';
const props = withDefaults(
  defineProps<{ name: string; color: string; size?: string; stroke?: string }>(),
  {
    color: 'text-primary',
    stroke: '1.5',
    size: '24'
  }
);
const sizeClass = ((size) => (size.match(/^\d+$/) ? size : `[${size}]`))(props.size);
const classAttributes = useAttrs();
</script>
