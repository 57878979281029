// @ts-nocheck
import type { AxiosRequestConfig } from "axios";
import axios from "./axios";
import type { JsonData } from "./axios";

type OnUploadProps = {
  name: string;
  loaded: number;
  total: number;
  percent: number;
  completed: boolean;
};

type OnUploadCallback = (info: OnUploadProps) => void;

export type FlattenedPostRequest<T = any> = (
  url: string,
  data?: JsonData,
  config?: AxiosRequestConfig<JsonData>
) => Promise<T>;

export type FlattenedGetRequest<T = any> = (
  url: string,
  config?: AxiosRequestConfig
) => Promise<T>;

export type FlattenedUploadRequest<T = any> = (
  url: string,
  name: string,
  data: string,
  onUploadCallback: OnUploadCallback,
  config?: AxiosRequestConfig
) => Promise<T>;

axios.defaults.headers.common['x-without-inertia'] = true;

const get: FlattenedGetRequest = (url, headers) =>
  axios.get(url, headers);

const post: FlattenedPostRequest = (url, data, config) =>
  axios.post(url, data, config);

const put: FlattenedPostRequest = (url, data, config) =>
  axios.put(url, data, config);

const erase: FlattenedGetRequest = (url) => axios.delete(url);

const upload: FlattenedUploadRequest = (url, name, data, onUploadCallback, config) =>
  axios.post(url, data, config ?? {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: ({ loaded, total }: OnUploadProps) => {
      const completed = loaded === total;
      const percent = (loaded / total) * 100;
      onUploadCallback({ name, loaded, total, percent, completed });
    },
  });

export default {
  get,
  post,
  put,
  erase,
  upload,
};
