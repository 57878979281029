import { defineStore } from 'pinia';
import client from '@/lib/client';

type LoginType = {
  email: string;
  password: string;
  remember: boolean;
};

type AuthStoreType = () => {
  user: UserType | null;
  token: string | null;
  forgotPasswordToken: string;
  permissions: string[];
  roles: string[];
  isFetchingUser: boolean;
};

const getDefaultAuthState: AuthStoreType = () => ({
  user: null,
  token: '',
  forgotPasswordToken: '',
  permissions: [],
  roles: [],
  isFetchingUser: false
});

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: () => ({ ...getDefaultAuthState() }),
  getters: {
    hasToken: (state): boolean => null !== state.token && '' !== state.token,
    isLoggedIn: (state): boolean => null !== state.user && null !== state.user?.email,
    name: (state): string => (state.user ? `${state.user?.firstName} ${state.user?.lastName}` : ''),
    email: (state): string => (state.user ? `${state.user?.email}` : ''),
    account: (state): AccountType => state.user || {}
  },
  actions: {
    async login({ email, password, remember }: LoginType) {
      const request = client.post('auth/check', { email, password, remember });

      return request.then(({ token }) => {
        this.$patch({ token });

        return this.fetchUser();
      });
    },
    async logout() {
      const request = new Promise((resolve, reject) => {
        if (null === this.token) {
          resolve(true);
        }

        client
          .post('secure/logout')
          .catch(reject)
          .finally(() => resolve(true));
      });

      request.finally(() => this.$reset());

      return request;
    },
    async fetchUser() {
      const request = client.get('secure/me');

      request
        .then((user) => this.$patch({ user }))
        .catch(({ status, data }) => {
          if (status === 401) {
            throw new Error(data.message);
          }
        })
        .finally(() => this.$patch({ isFetchingUser: false }));

      return request;
    }
  }
});

export type SignupStoreType = () => {
  referralCode: string | null;
  referer: UserType | null;
  loaded: boolean;
  loadError: string | null;
  form: Partial<AccountType>;
  errors: Record<string, string>;
};

const getDefaultAccountState: SignupStoreType = () => ({
  referralCode: null,
  referer: null,
  loaded: false,
  loadError: null,
  errors: {},
  form: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    referralCode: ''
  }
});

export const useSignupStore = defineStore('auth.signup', {
  persist: true,
  state: () => ({ ...getDefaultAccountState() }),
  getters: {
    refererLoaded: (state): AccountType => state.loaded
  },
  actions: {
    async getReferer(referralCode: string) {
      return client.get(`/auth/signup/referer/${referralCode}`).then((response) => {
        this.$patch({ referer: response, referralCode, loaded: true });
        return response;
      });
    },
    async verifyEmail(emailToken: string) {
      return client.post(`/auth/signup/verify-token`, { emailToken }).then(() => {
        // this.$reset();
      });
    },
    async register() {
      return client.post('auth/signup', this.form).then((response) => {
        // this.$reset();
        return response;
      });
    }
  }
});

export const useRecoverPasswordStore = defineStore('auth.recover_password', {
  persist: true,
  state: () => ({
    code: '',
    email: ''
  }),
  actions: {
    sendForgotPasswordEmail(form: $tsFixMe) {
      return client.post('auth/password/recover-email', form).then((response) => {
        const persistedState = { email: form.email };
        this.$patch(persistedState);
        return response;
      });
    },
    verifyForgotPasswordCode(form: $tsFixMe) {
      return client.post('auth/password/verify-code', form).then((response) => {
        const persistedState = { code: form.passwordRecoveryCode };
        this.$patch(persistedState);
        return response;
      });
    },
    resetPassword(form: $tsFixMe) {
      return client.post('auth/password/reset', form).then((response) => {
        return response;
      });
    },
    resetEmail() {
      this.$patch({
        email: ''
      });
    },
    resetCode() {
      this.$patch({
        code: ''
      });
    }
  }
});
