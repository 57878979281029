<template>
  <info-modal v-if="shouldShowModal" :allows-close="false" icon="info" type="info">
    <template #title>
      <h4>Subscription Notification</h4>
    </template>
    <div class="p-4">
      Hi, {{ user.firstName }}! you must have an active subscription in order to continue
    </div>

    <template #footer>
      <form-button @click="() => $router.back()" class="mr-1" icon="undo-2">Back</form-button>
      <form-button
        @click="() => $router.push({ name: 'subscription.renew' })"
        color="secondary"
        icon="cog"
      >
        Manage Subscription
      </form-button>
    </template>
  </info-modal>

  <!-- TODO: Add Subscription reminder notification -->
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import InfoModal from '@/components/Modal/InfoModal.vue';
import FormButton from '@/components/Form/User/FormButton.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const auth = useAuthStore();
const route = useRoute();

const user = auth.user;
const isSubscribed = auth.user?.isSubscribed;
// const subscription = auth.user.subscription;

// TODO: Show a different message if the user's subscription is expired

// TODO: move to config
const protectedRoutePrefixes = ['network', 'profile', 'settings'];

const shouldShowModal = computed(() => {
  const isProtectedRoute = protectedRoutePrefixes.some((prefix) =>
    (route.name as string)?.startsWith(prefix)
  );
  return !isSubscribed && isProtectedRoute;
});
</script>
