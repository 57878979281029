import jQuery from 'jquery';
import tippy from 'tippy.js';

// Side Menu Tooltips
export const initTooltips = (function tooltips() {
  jQuery('.side-menu').each(function () {
    if (this._tippy == undefined) {
      const content = jQuery(this)
        .find('.side-menu__title')
        .html()
        .replace(/<[^>]*>?/gm, '')
        .trim();
      tippy(this, {
        content: content,
        arrow: roundArrow,
        animation: 'shift-away',
        placement: 'right'
      });
    }

    if (
      jQuery(window).width() <= 1260 ||
      jQuery(this).closest('.side-nav').hasClass('side-nav--simple')
    ) {
      this._tippy.enable();
    } else {
      this._tippy.disable();
    }
  });

  return tooltips;
})();

window.addEventListener('resize', () => {
  initTooltips();
});
