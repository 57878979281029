<template>
  <div class="flex items-center space-x-2">
    <span class="text-sm bg-green-400 p-1 px-2 br-2 rounded">
      {{ version }}
    </span>
  </div>
</template>

<script lang="ts" setup>
const version = document.getElementById('app')?.dataset.version;
</script>
