export default [
  {
    name: 'home',
    path: '',
    component: () => import('@/pages/login.vue')
  },
  {
    name: 'register',
    path: 'register',
    component: () => import('@/pages/register.vue')
  },
  {
    name: 'verify.form',
    path: 'verify-email',
    component: () => import('@/pages/verify.vue')
  },
  {
    name: 'verify',
    path: 'verify-email/:token',
    component: () => import('@/pages/verify.vue')
  },
  {
    name: 'join',
    path: 'register/:referral_code/join',
    component: () => import('@/pages/register.vue')
  },
  {
    name: 'forgot-password',
    path: 'forgot-password',
    component: () => import('@/pages/forgot-password.vue')
  },
  {
    name: 'verify-forgot-password-code',
    path: 'verify-forgot-password-code',
    component: () => import('@/pages/verify-forgot-password-code.vue')
  },
  {
    name: 'change-password',
    path: 'change-password',
    component: () => import('@/pages/change-password.vue')
  }
];
