<template>
  <li>
    <a
      href="#"
      :class="`side-menu ${isCollapsed ? 'side-menu--open' : ''}`"
      @click.prevent.stop="toggleCollapse"
    >
      <div class="side-menu__icon">
        <i data-tw-merge="" :data-lucide="icon" class="stroke-1.5 w-5 h-5"></i>
      </div>
      <div class="side-menu__title">
        {{ label }}
        <div :class="`side-menu__sub-icon ${isCollapsed ? 'transform rotate-180' : ''}`">
          <i data-tw-merge="" data-lucide="chevron-down" class="stroke-1.5 w-5 h-5"></i>
        </div>
      </div>
    </a>
    <ul :class="isCollapsed ? 'side-menu__sub-open' : 'd-none'">
      <slot></slot>
    </ul>
  </li>
</template>

<script setup lang="ts">
import { useMenuStore } from '@/stores/menu';
import { computed, ref, watch } from 'vue';

const { id } = withDefaults(defineProps<{ id: string; label?: string; icon: string }>(), {
  label: '-- unlabelled menu --',
  icon: 'info'
});

const isCollapsed = ref(false);
const menuStore = useMenuStore();
const active = computed<boolean>(() => menuStore.menus[id]?.children.some((child) => child.active));

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

watch(active, (value) => {
  isCollapsed.value = value;
});
</script>
