import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createWebHistory, createRouter } from 'vue-router';
import App from '@/components/App.vue';
import TwIcon from '@/components/Icons/TwIcon.vue';
import routes from '@/routes';
import ClickOutsideDirective from '@/directives/click-outside';
import { APP_ENV, SENTRY_DSN } from './lib/constants';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

if (APP_ENV == 'production') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(pinia);

app.use(router);

app.component('TwIcon', TwIcon);

app.directive('click-outside', ClickOutsideDirective);

app.mount('#app');
