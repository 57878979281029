<template>
  <div data-tw-merge="" data-tw-placement="bottom-end" class="dropdown relative">
    <button
      data-tw-toggle="dropdown"
      aria-expanded="false"
      class="cursor-pointer image-fit zoom-in intro-x block h-8 w-8 scale-110 overflow-hidden rounded-full shadow-lg"
    >
      <img src="/static/images/fakers/profile-12.jpg" alt="OMACK Network" />
    </button>
    <div
      data-transition=""
      data-selector=".show"
      data-enter="transition-all ease-linear duration-150"
      data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
      data-enter-to="!mt-1 visible opacity-100 translate-y-0"
      data-leave="transition-all ease-linear duration-150"
      data-leave-from="!mt-1 visible opacity-100 translate-y-0"
      data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
      class="dropdown-menu absolute z-[9999] hidden"
    >
      <div
        data-tw-merge=""
        class="dropdown-content rounded-md border-transparent p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 relative mt-px w-56 bg-theme-1/80 text-white before:absolute before:inset-0 before:z-[-1] before:block before:rounded-md before:bg-black"
      >
        <div class="p-2 font-medium font-normal">
          <div class="font-medium">{{ auth.name }}</div>
          <div class="mt-0.5 text-xs text-white/70 dark:text-slate-500">
            {{ auth.roles.includes('admin') ? 'Admin' : 'Agent' }}
          </div>
        </div>
        <div class="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400 bg-white/[0.08]"></div>
        <a
          class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
          ><i data-tw-merge="" data-lucide="user" class="stroke-1.5 mr-2 h-4 w-4"></i> Profile</a
        >
        <a
          class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
          ><i data-tw-merge="" data-lucide="edit" class="stroke-1.5 mr-2 h-4 w-4"></i> Add
          Account</a
        >
        <a
          class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
          ><i data-tw-merge="" data-lucide="lock" class="stroke-1.5 mr-2 h-4 w-4"></i> Reset
          Password</a
        >
        <a
          class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
          ><i data-tw-merge="" data-lucide="help-circle" class="stroke-1.5 mr-2 h-4 w-4"></i>
          Help</a
        >
        <div class="h-px my-2 -mx-2 dark:bg-darkmode-400 bg-white/[0.08]"></div>
        <router-link
          :to="{ name: 'logout' }"
          class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"
          ><i data-tw-merge="" data-lucide="toggle-right" class="stroke-1.5 mr-2 h-4 w-4"></i>
          Logout</router-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'App',
  setup() {
    const auth = useAuthStore();

    return {
      auth
    };
  }
});
</script>
