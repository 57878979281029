<template>
  <div
    class="h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-5 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700 before:content-[''] before:absolute before:h-[65px] before:inset-0 before:top-0 before:mx-7 before:bg-primary/30 before:mt-3 before:rounded-xl before:hidden before:md:block before:dark:bg-darkmode-600/30 after:content-[''] after:absolute after:inset-0 after:h-[65px] after:mx-3 after:bg-primary after:mt-5 after:rounded-xl after:shadow-md after:hidden after:md:block after:dark:bg-darkmode-600"
  >
    <div class="flex h-full items-center">
      <a href="" class="-intro-x hidden md:flex xl:w-[180px]">
        <img class="w-6" src="/static/images/logo-small.png" alt="OMACK Network" />
        <span class="ml-3 text-lg text-white hidden xl:block"> OMACK Network </span>
      </a>

      <breadcrumbs />

      <top-searchbar />

      <notifications />

      <account-menu />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import Breadcrumbs from '@/components/Layouts/Secure/Breadcrumbs.vue';
import TopSearchbar from '@/components/Layouts/Secure/TopSearchbar.vue';
import Notifications from '@/components/Layouts/Secure/Notifications.vue';
import AccountMenu from '@/components/Layouts/Secure/AccountMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    Breadcrumbs,
    TopSearchbar,
    Notifications,
    AccountMenu
  },
  setup() {
    const auth = useAuthStore();

    return {
      auth
    };
  }
});
</script>
