import axios from "axios";
import { API_URL } from "./constants";

const apiUrl = API_URL;
const apiRoute = "/api/";
const baseURL = apiUrl + apiRoute;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export interface JsonData {
  [key: string]: any;
}

const getAuthorizationToken = () => {
  const token = localStorage.getItem("_token");
  const authorizationToken = `Bearer ${token}`;
  return authorizationToken;
};

const setAuthorizationToken = (token: string) => {
  localStorage.setItem("_token", token);
};

const isErrorInUrl = (url: string) => typeof url === "object" && url !== null;

axios.interceptors.request.use(
  (request) => {
    switch (true) {
      case isErrorInUrl(request.url as string):
        return Promise.reject(request.url);

      case request.url !== "/auth/check":
        if (request.headers) {
          request.headers.Authorization = getAuthorizationToken();
        }
        return request;

      default:
        return request;
    }
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  ({ config, data }) => {
    /**
     * Saving access token in axios volatile memory
     * for now till we sort out our authentication strategy
     */
    if (config.url === "auth/check" && config.method === "post" && data.token) {
      // Save the bearerToken
      setAuthorizationToken(data.token);
    }
    // Edit response config
    return data;
  },
  (error) => Promise.reject(error.response)
);

export default axios;
