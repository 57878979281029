<template>
  <li>
    <router-link :to="{ name: to }" :class="`side-menu ${active ? 'side-menu--active' : ''}`">
      <div class="side-menu__icon">
        <i data-tw-merge="" :data-lucide="icon" class="stroke-1.5 w-5 h-5"></i>
      </div>
      <div class="side-menu__title">{{ label }}</div>
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { useMenuStore } from '@/stores/menu';

const { to, label } = withDefaults(defineProps<{ label?: string; icon: string; to: string }>(), {
  label: '-- unlabelled menu --',
  icon: 'info',
  to: 'home'
});

const router = useRouter();
const instance = getCurrentInstance();
const menuStore = useMenuStore();
const active = computed(() => router.currentRoute.value.name === to);

const registerMenu = () =>
  menuStore.registerSubmenu(instance?.parent?.props.id as string, {
    to,
    label,
    active: active.value
  });

onUpdated(registerMenu);
onMounted(registerMenu);
</script>
