export default [
  {
    name: 'dashboard',
    path: 'dashboard',
    component: () => import('@/pages/dashboard.vue')
  },
  {
    name: 'subscription.renew',
    path: 'subscription/subscribe',
    component: () => import('@/pages/subscription/subscribe.vue')
  },
  {
    name: 'subscription.payment',
    path: 'subscription/:id/payment',
    component: () => import('@/pages/subscription/pay.vue')
  },
  {
    name: 'logout',
    path: 'logout',
    component: () => import('@/pages/logout.vue')
  },
  {
    name: 'settings.profile',
    path: 'settings/profile',
    component: () => import('@/pages/dashboard.vue')
  },
  {
    name: 'network.table',
    path: 'network/table',
    component: () => import('@/pages/dashboard.vue')
  },
  {
    name: 'network.tree',
    path: 'network/tree',
    component: () => import('@/pages/dashboard.vue')
  }
];
