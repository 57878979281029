<template>
  <nav
    aria-label="breadcrumb"
    class="flex h-[45px] md:ml-10 md:border-l border-white/[0.08] dark:border-white/[0.08] mr-auto -intro-x md:pl-6"
  >
    <ol class="flex items-center text-theme-1 dark:text-slate-300 text-white/90">
      <li class="">
        <a href="">Application</a>
      </li>
      <li
        class="relative ml-5 pl-0.5 before:content-[''] before:w-[14px] before:h-[14px] before:bg-chevron-white before:transform before:rotate-[-90deg] before:bg-[length:100%] before:-ml-[1.125rem] before:absolute before:my-auto before:inset-y-0 dark:before:bg-chevron-white text-white/70"
      >
        <a href="">Dashboard</a>
      </li>
    </ol>
    <span class="ml-3 mt-2">
      <app-version />
    </span>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppVersion from '@/components/AppVersion.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppVersion
  }
});
</script>
