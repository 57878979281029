import { defineStore } from 'pinia';

type MenuType = {
  children: MenuInfo[];
};

type MenuStoreType = () => {
  menus: Record<string, MenuType>;
};

const getDefaultAuthState: MenuStoreType = () => ({
  menus: {}
});

type MenuInfo = {
  to: string;
  label: string;
  active: boolean;
};

export const useMenuStore = defineStore('menu', {
  state: () => ({ ...getDefaultAuthState() }),
  actions: {
    registerSubmenu(parent: string | null | undefined, info: MenuInfo): void {
      if (undefined === parent || null === parent) {
        return;
      }

      const parentMenu = this.menus[parent];

      if (!parentMenu) {
        this.menus[parent] = {
          children: []
        };

        return this.registerSubmenu(parent, info);
      }

      if (parentMenu.children.find((child) => child.to === info.to)) {
        return this.$patch({
          menus: {
            ...this.menus,
            [parent]: {
              children: parentMenu.children.map((child) => {
                if (child.label === info.label) {
                  return info;
                }

                return child;
              })
            }
          }
        });
      }

      parentMenu.children.push(info);
    },
    clearMenus(): void {
      this.$reset();
    }
  }
});
