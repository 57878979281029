<template>
  <div
    data-tw-merge=""
    data-tw-placement="bottom-end"
    class="dropdown relative intro-x mr-4 sm:mr-6"
  >
    <div
      data-tw-toggle="dropdown"
      aria-expanded="false"
      class="cursor-pointer relative block text-white/70 outline-none before:absolute before:right-0 before:top-[-2px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-danger before:content-['']"
    >
      <i data-tw-merge="" data-lucide="bell" class="stroke-1.5 w-5 h-5 dark:text-slate-500"></i>
    </div>
    <div
      data-transition=""
      data-selector=".show"
      data-enter="transition-all ease-linear duration-150"
      data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
      data-enter-to="!mt-1 visible opacity-100 translate-y-0"
      data-leave="transition-all ease-linear duration-150"
      data-leave-from="!mt-1 visible opacity-100 translate-y-0"
      data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
      class="dropdown-menu absolute z-[9999] hidden"
    >
      <div
        data-tw-merge=""
        class="dropdown-content rounded-md border-transparent bg-white shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 mt-2 w-[280px] p-5 sm:w-[350px]"
      >
        <div class="mb-5 font-medium">Notifications</div>
        <div class="cursor-pointer relative flex items-center">
          <div class="image-fit relative mr-1 h-12 w-12 flex-none">
            <img
              class="rounded-full"
              src="/static/images/fakers/profile-12.jpg"
              alt="OMACK Network"
            />
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="mr-5 truncate font-medium" href="">{{ auth.name }}</a>
              <div class="ml-auto whitespace-nowrap text-xs text-slate-400">05:09 AM</div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500
            </div>
          </div>
        </div>
        <div class="cursor-pointer relative flex items-center mt-5">
          <div class="image-fit relative mr-1 h-12 w-12 flex-none">
            <img
              class="rounded-full"
              src="/static/images/fakers/profile-12.jpg"
              alt="OMACK Network"
            />
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="mr-5 truncate font-medium" href=""> Kevin Spacey </a>
              <div class="ml-auto whitespace-nowrap text-xs text-slate-400">01:10 PM</div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500
            </div>
          </div>
        </div>
        <div class="cursor-pointer relative flex items-center mt-5">
          <div class="image-fit relative mr-1 h-12 w-12 flex-none">
            <img
              class="rounded-full"
              src="/static/images/fakers/profile-12.jpg"
              alt="OMACK Network"
            />
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="mr-5 truncate font-medium" href=""> Tom Cruise </a>
              <div class="ml-auto whitespace-nowrap text-xs text-slate-400">05:09 AM</div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500">
              There are many variations of passages of Lorem Ipsum available, but the majority have
              suffered alteration in some form, by injected humour, or randomi
            </div>
          </div>
        </div>
        <div class="cursor-pointer relative flex items-center mt-5">
          <div class="image-fit relative mr-1 h-12 w-12 flex-none">
            <img
              class="rounded-full"
              src="/static/images/fakers/profile-12.jpg"
              alt="OMACK Network"
            />
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="mr-5 truncate font-medium" href=""> Johnny Depp </a>
              <div class="ml-auto whitespace-nowrap text-xs text-slate-400">06:05 AM</div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500">
              It is a long established fact that a reader will be distracted by the readable content
              of a page when looking at its layout. The point of using Lorem
            </div>
          </div>
        </div>
        <div class="cursor-pointer relative flex items-center mt-5">
          <div class="image-fit relative mr-1 h-12 w-12 flex-none">
            <img
              class="rounded-full"
              src="/static/images/fakers/profile-12.jpg"
              alt="OMACK Network"
            />
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
            ></div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="mr-5 truncate font-medium" href=""> Kevin Spacey </a>
              <div class="ml-auto whitespace-nowrap text-xs text-slate-400">05:09 AM</div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500">
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
              piece of classical Latin literature from 45 BC, making it over 20
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const auth = useAuthStore();

    return {
      auth
    };
  }
});
</script>
