<template>
  <subscription-modal />
  <div
    class="enigma py-5 px-5 md:py-0 sm:px-8 md:px-0 before:content-[''] before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 dark:before:from-darkmode-800 dark:before:to-darkmode-800 md:before:bg-none md:bg-slate-200 md:dark:bg-darkmode-800 before:fixed before:inset-0 before:z-[-1]"
  >
    <mobile-menu />
    <top-bar />
    <div class="flex overflow-hidden">
      <side-menu />
      <div
        v-if="isLoggedIn"
        class="max-w-full md:max-w-none rounded-[30px] md:rounded-none px-4 md:px-[22px] min-w-0 min-h-screen bg-slate-100 flex-1 md:pt-20 pb-10 mt-5 md:mt-1 relative dark:bg-darkmode-700 before:content-[''] before:w-full before:h-px before:block"
      >
        <slot><RouterView /></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated } from 'vue';
import { useRouter, type NavigationGuardNext } from 'vue-router';
import jQuery from 'jquery';
import { createIcons, icons } from 'lucide';
import { useAuthStore } from '@/stores/auth';
import SubscriptionModal from '@/components/Modal/SubscriptionModal.vue';
import '@/app.scss';
// @ts-expect-error missing types
import { initTooltips } from '@/theme/js/themes/enigma';
import TopBar from '@/components/Layouts/Secure/TopBar.vue';
import MobileMenu from '@/components/Layouts/Secure/MobileMenu.vue';
import SideMenu from '@/components/Layouts/Secure/SideMenu.vue';
import prompt from '@/lib/prompts';

export default defineComponent({
  name: 'App',
  components: {
    TopBar,
    MobileMenu,
    SideMenu,
    SubscriptionModal
  },
  setup(_, { attrs }) {
    const router = useRouter();
    const auth = useAuthStore();
    let fetchUserRequest: Promise<$tsFixMe> | null = null;

    const loadUserDetails = async () => {
      if (auth.hasToken) {
        if (null !== fetchUserRequest) {
          return;
        }

        fetchUserRequest = auth.fetchUser();
        fetchUserRequest
          .catch(() => auth.logout().then(() => router.push({ name: 'home' })))
          .finally(() => (fetchUserRequest = null));
      }
    };

    const handleLogout = (next: NavigationGuardNext) => {
      prompt
        .fire({
          title: 'Are you sure you want to logout?',
          text: 'You are about to be logged out!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed!'
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    };

    const initIcons = () => setTimeout(() => createIcons({ icons, nameAttr: 'data-lucide' }), 100);

    router.beforeEach((to, from, next) => {
      if (undefined !== from.name && to.name === 'logout') {
        return handleLogout(next);
      }

      if (null === to.name) {
        return next({ name: '404' });
      }

      if (to.fullPath.includes('secure') && from.fullPath.includes('secure')) {
        loadUserDetails();
      }

      if (to.fullPath.includes('secure') && !auth.isLoggedIn) {
        router.push({ name: 'home' });
      } else if (!to.fullPath.includes('secure') && auth.isLoggedIn) {
        router.push({ name: 'dashboard' });
      } else {
        next();
      }
    });

    router.afterEach((to, from) => {
      console.log('Route changed');
      console.log({ from, to });
    });

    onMounted(() => {
      initTooltips();
      initIcons();
      loadUserDetails();

      jQuery('.dropdown-menu').on('click', 'a', () => jQuery('.dropdown-menu').hide());
    });

    onUpdated(() => {
      initIcons();
    });

    return {
      props: attrs,
      isLoggedIn: auth.isLoggedIn
    };
  }
});
</script>
