import authRoutes from './auth';
import secureRoutes from './secure';
import AuthLayout from '@/components/Layouts/Auth/Layout.vue';
import DashboardLayout from '@/components/Layouts/Secure/Layout.vue';

export default [
  {
    path: '/secure',
    component: DashboardLayout,
    children: secureRoutes
  },
  {
    path: '/',
    component: AuthLayout,
    children: authRoutes
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/404.vue')
  }
];
