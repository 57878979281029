<template>
  <li>
    <router-link :to="{ name: to }" :class="`side-menu ${active ? 'side-menu--active' : ''}`">
      <div class="side-menu__icon">
        <i data-tw-merge="" :data-lucide="icon" class="stroke-1.5 w-5 h-5"></i>
      </div>
      <div class="side-menu__title">{{ label }}</div>
      <slot></slot>
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const { to } = withDefaults(defineProps<{ label?: string; icon: string; to: string }>(), {
  label: '-- new unlabelled menu --',
  icon: 'info',
  to: 'home'
});

const router = useRouter();
const active = computed(() => router.currentRoute.value.name === to);
</script>
