<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import '@/app.scss';

export default defineComponent({
  name: 'App',
  setup(_, { attrs }) {
    const router = useRouter();
    const auth = useAuthStore();

    router.beforeEach((to, from, next) => {
      if (null === to.name) {
        return next({ name: '404' });
      }

      if (to.fullPath.includes('secure') && !auth.hasToken) {
        router.push({ name: 'home' });
      } else {
        next();
      }
    });

    return {
      props: attrs
    };
  }
});
</script>
