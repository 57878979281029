<template>
  <button
    data-tw-merge
    type="button"
    :class="{
      [`transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-${color} border-${color} text-white dark:border-${color}`]: true,
      'bg-opacity-90 border-opacity-90': !disabled,
      'bg-opacity-70 border-opacity-70': disabled,
      [colors]: true
    }"
    :disabled="disabled"
    v-on:click="handleClick"
  >
    <tw-icon v-if="icon" :name="icon" :color="iconColor" class="mr-2" size="4" />
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, computed } from 'vue';

type ButtonType = 'submit' | 'button' | 'reset';
type ColorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';
const props = withDefaults(
  defineProps<{ disabled?: boolean; type?: ButtonType; icon?: string; color?: ColorType }>(),
  {
    disabled: false,
    type: 'button',
    icon: '',
    color: 'primary'
  }
);
const emit = defineEmits(['click']);
const isDarkColor = ['dark', 'primary', 'danger', 'secondary'].includes(props.color);
const iconColor = isDarkColor ? 'text-white' : 'text-gray-900';

const colorMap: Record<ColorType, string> = {
  primary:
    'bg-blue-700 dark:bg-blue-600 dark:focus:ring-blue-800 dark:hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium hover:bg-blue-800 rounded-lg text-sm text-white',
  info: 'bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-700 dark:hover:bg-gray-700 dark:hover:text-white dark:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-100 focus:z-10 font-medium hover:bg-gray-100 hover:text-blue-700 rounded-lg text-gray-900 text-sm',
  secondary:
    'bg-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:focus:ring-gray-700 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium hover:bg-gray-900 rounded-lg text-sm text-white',
  light:
    'bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-700 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:text-white focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium hover:bg-gray-100 rounded-lg text-gray-900 text-sm',
  success:
    'bg-green-700 dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium hover:bg-green-800 rounded-lg text-sm text-white',
  danger:
    'bg-red-700 dark:bg-red-600 dark:focus:ring-red-900 dark:hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium hover:bg-red-800 rounded-lg text-sm text-white',
  warning:
    'bg-yellow-400 dark:focus:ring-yellow-900 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium hover:bg-yellow-500 rounded-lg text-sm text-white',
  dark: 'bg-yellow-400 dark:focus:ring-yellow-900 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium hover:bg-yellow-500 rounded-lg text-sm text-white'
};

const colors = computed(() => colorMap[props.color]);

const handleClick = (e: Event) => {
  if (props.disabled) {
    return;
  }

  if (props.type === 'button') {
    e.preventDefault();
    emit('click');
  }
};
</script>
